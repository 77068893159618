import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, WeeklyAccessFeeStructure } from '@/api/types'

export default class WeeklyAccessFeeStructureService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<WeeklyAccessFeeStructure[]>> {
    return await this.call('/v1/dpp/weekly-access-fee-structures', options)
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<WeeklyAccessFeeStructure>> {
    return await this.call(`/v1/dpp/weekly-access-fee-structures/${id}`, options)
  }
}
