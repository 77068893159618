import { ApiServiceBase } from '@/api/services/ApiServiceBase'
import type { FetchOptions } from 'ofetch'
import type { ApiPaginatedResponse, ApiResponse, Invoice } from '@/api/types'

export default class InvoiceSummaryStatementService extends ApiServiceBase {
  async all(options?: FetchOptions<'json'>): Promise<ApiPaginatedResponse<Invoice[]>> {
    return await this.call(`/v1/dpp/invoice-summary-statements`, options)
  }

  async download(options?: FetchOptions<'blob'>): Promise<Blob> {
    const _options = useCloneDeep(options)
    return await this.call('/v1/dpp/invoice-summary-statements', useMerge(_options, { query: { download: true } }))
  }

  async retrieve(id: string | number, options?: FetchOptions<'json'>): Promise<ApiResponse<Invoice>> {
    return await this.call(`/v1/dpp/invoice-summary-statements/${id}`, options)
  }
}
